<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
      'PIX': 'PIX',
      'Save': 'Salvar',
      'Type': 'Tipo',
      'Key': 'Chave',
      'The key is required.': 'A chave é obrigatório',
      'The type of the key is mandatory.': 'O tipo de chave é obrigatório'
    },
    es: {
      'PIX': 'PIX',
      'Save': 'Salvar',
      'Type': 'Escribe',
      'Key': 'llave',
      'The key is required.': 'Se requiere la clave',
      'The type of the key is mandatory.': 'El tipo de clave es obligatorio'
    }
  },
  components: { Layout },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      alert: {
        pix: { type: '', message: '' }
      },

      loading: false,

      pix: {
        type: null,
        key: null,
        types: {
          cpf: 'CPF',
          cnpj: 'CNPJ',
          cellphone: 'Telefone',
          email:'E-mail',
          random:'Chave Aleatória'
        },
      }
    };
  },
  validations: {
    pix: {
      type: { required },
      key: { required }
    }
  },
  methods: {
    getPix() {
      api
      .get('user/pix')
      .then((response) => {
        if (response.data.status == 'success') {
          this.pix.type = response.data.type,
          this.pix.key = response.data.key
        }
      })
    },
    pixSubmit() {
      this.loading = true
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.loading = false
        return;
      } else {
        const { type, key } = this.pix;
        if (type && key) {
          api
          .post('user/pix', {
            key: this.pix.key,
            type: this.pix.type
          })
          .then(response => {
            if (response.data.status=='success') {
              this.$v.$reset()

              this.alert.pix.type = 'alert-success'
              this.alert.pix.message = 'A chave foi cadastrada com sucesso!'
            } else {
              this.alert.pix.type = 'alert-danger'
              this.alert.pix.message = response.data.message
            }

            this.loading = false
          })
          .catch(error => {
            if (error) {
              this.loading = false
            }
          })
        }
      }
    },
  },
  mounted() {
    this.getPix()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-xl-7">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('PIX') }}</h4>
        </div>
        <div v-if="alert.pix.message" :class="'alert ' + alert.pix.type">{{ t(alert.pix.message) }}</div>
        <div class="card">
          <div class="card-body table-responsive">
            <b-form class="p-3" @submit.prevent="pixSubmit">
              <img src="@/assets/images/logo-pix.png" class="mb-4" style="height:50px;">
              <b-form-group id="banco" :label="t('Type')" label-for="banco">
                <select v-model="pix.type" class="custom-select mb-2">
                  <option v-for="(pix, index) in pix.types" :key="index" :value="index">{{ pix }}</option>
                </select>
                <div v-if="$v.pix.type.$error" class="invalid-feedback">
                  <span v-if="!$v.pix.type.required">{{ t('The type of the key is mandatory.') }}</span>
                </div>
              </b-form-group>
              <b-form-group id="agencia.numero" :label="t('Key')" label-for="agencia.numero">
                <b-form-input id="agencia.numero" v-model="pix.key" type="text" :class="{ 'is-invalid': $v.pix.key.$error }"></b-form-input>
                <div v-if="$v.pix.key.$error" class="invalid-feedback">
                  <span v-if="!$v.pix.key.required">{{ t('The key is required.') }}</span>
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button :disabled="this.loading == true" type="submit" variant="primary">
                  {{ t('Save') }}
                  <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>